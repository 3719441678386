export default {
  af: {
    key3: 'AFG',
    name: 'Afghanistan',
    name3: 'Afghanistan',
    nameFr: 'Afghanistan',
    phoneCode: '93',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫افغانستان‬‎',
    nationality: 'Afghan',
    states: {}
  },
  ax: {
    key3: 'ALA',
    name: 'Aland Islands',
    name3: 'Åland Islands',
    nameFr: 'Åland (les Îles)',
    phoneCode: '358',
    areaCode: ['18'],
    phoneOrder: 1,
    localName: 'Åland Islands',
    nationality: 'Swedish (Aland Islands)',
    states: {}
  },
  al: {
    key3: 'ALB',
    name: 'Albania',
    name3: 'Albania',
    nameFr: 'Albanie',
    phoneCode: '355',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Shqipëri',
    nationality: 'Albanian',
    states: {}
  },
  dz: {
    key3: 'DZA',
    name: 'Algeria',
    name3: 'Algeria',
    nameFr: 'Algérie',
    phoneCode: '213',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫الجزائر‬‎',
    nationality: 'Algerian',
    states: {}
  },
  aq: {
    key3: 'ATA',
    name: 'Antarctica',
    name3: 'Antarctica',
    nameFr: 'Antarctique',
    phoneCode: '',
    localName: '',
    nationality: 'Antarctica',
    states: {}
  },
  ad: {
    key3: 'AND',
    name: 'Andorra',
    name3: 'Andorra',
    nameFr: 'Andorre',
    phoneCode: '376',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Andorran',
    states: {}
  },
  ao: {
    name: 'Angola',
    name3: 'Angola',
    nameFr: 'Angola',
    key3: 'AGO',
    phoneCode: '244',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Angolan',
    states: {}
  },
  ai: {
    key3: 'AIA',
    name: 'Anguilla',
    name3: 'Anguilla',
    nameFr: 'Anguilla',
    phoneCode: '1',
    areaCode: ['264'],
    phoneOrder: 6,
    localName: '',
    nationality: 'Anguillian',
    states: {}
  },
  ag: {
    key3: 'ATG',
    name: 'Antigua and Barbuda',
    name3: 'Antigua and Barbuda',
    nameFr: 'Antigua-et-Barbuda',
    phoneCode: '1',
    areaCode: ['268'],
    phoneOrder: 7,
    localName: '',
    nationality: 'Antiguan, Barbudan',
    states: {}
  },
  ar: {
    key3: 'ARG',
    name: 'Argentina',
    name3: 'Argentina',
    nameFr: 'Argentine',
    phoneCode: '54',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Argentinean',
    states: {}
  },
  am: {
    key3: 'ARM',
    name: 'Armenia',
    name3: 'Armenia',
    nameFr: 'Arménie',
    phoneCode: '374',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Հայաստան',
    nationality: 'Armenian',
    states: {}
  },
  aw: {
    key3: 'ABW',
    name: 'Aruba',
    name3: 'Aruba',
    nameFr: 'Aruba',
    phoneCode: '297',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Aruban',
    states: {}
  },
  au: {
    key3: 'AUS',
    name: 'Australia',
    name3: 'Australia',
    nameFr: 'Australie',
    phoneCode: '61',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Australian',
    states: {
      ACT: 'Australian Capital Territory',
      NSW: 'New South Wales',
      NT: 'Northern Territory',
      QLD: 'Queensland',
      SA: 'South Australia',
      TAS: 'Tasmania',
      VIC: 'Victoria',
      WA: 'Western Australia'
    }
  },
  at: {
    key3: 'AUT',
    name: 'Austria',
    name3: 'Austria',
    nameFr: 'Autriche',
    phoneCode: '43',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Österreich',
    nationality: 'Austrian',
    states: {}
  },
  az: {
    key3: 'AZE',
    name: 'Azerbaijan',
    name3: 'Azerbaijan',
    nameFr: 'Azerbaïdjan',
    phoneCode: '994',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Azərbaycan',
    nationality: 'Azerbaijani',
    states: {}
  },
  bs: {
    key3: 'BHS',
    name: 'Bahamas',
    name3: 'Bahamas',
    nameFr: 'Bahamas',
    phoneCode: '1',
    areaCode: ['242'],
    phoneOrder: 8,
    localName: '',
    nationality: 'Bahamian',
    states: {}
  },
  bh: {
    key3: 'BHR',
    name: 'Bahrain',
    name3: 'Bahrain',
    nameFr: 'Bahreïn',
    phoneCode: '973',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫البحرين‬‎',
    nationality: 'Bahraini',
    states: {}
  },
  bd: {
    key3: 'BGD',
    name: 'Bangladesh',
    name3: 'Bangladesh',
    nameFr: 'Bangladesh',
    phoneCode: '880',
    areaCode: [],
    phoneOrder: 0,
    localName: 'বাংলাদেশ',
    nationality: 'Bangladeshi',
    states: {}
  },
  bb: {
    key3: 'BRB',
    name: 'Barbados',
    name3: 'Barbados',
    nameFr: 'Barbade',
    phoneCode: '1',
    areaCode: ['246'],
    phoneOrder: 9,
    localName: '',
    nationality: 'Barbadian',
    states: {}
  },
  by: {
    key3: 'BLR',
    name: 'Belarus',
    name3: 'Belarus',
    nameFr: 'Bélarus',
    phoneCode: '375',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Беларусь',
    nationality: 'Belarusian',
    states: {}
  },
  be: {
    key3: 'BEL',
    name: 'Belgium',
    name3: 'Belgium',
    nameFr: 'Belgique',
    phoneCode: '32',
    areaCode: [],
    phoneOrder: 0,
    localName: 'België',
    nationality: 'Belgian',
    states: {}
  },
  bz: {
    key3: 'BLZ',
    name: 'Belize',
    name3: 'Belize',
    nameFr: 'Belize',
    phoneCode: '501',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Belizean',
    states: {}
  },
  bj: {
    key3: 'BEN',
    name: 'Benin',
    name3: 'Benin',
    nameFr: 'Bénin',
    phoneCode: '229',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Bénin',
    nationality: 'Beninese',
    states: {}
  },
  bm: {
    key3: 'BMU',
    name: 'Bermuda',
    name3: 'Bermuda',
    nameFr: 'Bermudes',
    phoneCode: '1',
    areaCode: ['441'],
    phoneOrder: 10,
    localName: '',
    nationality: 'Bermudian',
    states: {}
  },
  bt: {
    key3: 'BTN',
    name: 'Bhutan',
    name3: 'Bhutan',
    nameFr: 'Bhoutan',
    phoneCode: '975',
    areaCode: [],
    phoneOrder: 0,
    localName: 'འབྲུག',
    nationality: 'Bhutanese',
    states: {}
  },
  bv: {
    key3: 'BVT',
    name: 'Bouvet Island',
    name3: 'Bouvet Island',
    nameFr: "Bouvet (l'Île)",
    phoneCode: '',
    localName: '',
    nationality: 'Bouvet Island',
    states: {}
  },
  bo: {
    key3: 'BOL',
    name: 'Bolivia, Plurinational State of',
    name3: 'Bolivia (Plurinational State of)',
    nameFr: 'Bolivie (État plurinational de)',
    phoneCode: '591',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Bolivian',
    states: {}
  },
  ba: {
    key3: 'BIH',
    name: 'Bosnia and Herzegovina',
    name3: 'Bosnia and Herzegovina',
    nameFr: 'Bosnie-Herzégovine',
    phoneCode: '387',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Босна и Херцеговина',
    nationality: 'Bosnian, Herzegovinian',
    states: {}
  },
  bw: {
    key3: 'BWA',
    name: 'Botswana',
    name3: 'Botswana',
    nameFr: 'Botswana',
    phoneCode: '267',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Batswana, Motswana',
    states: {}
  },
  br: {
    key3: 'BRA',
    name: 'Brazil',
    name3: 'Brazil',
    nameFr: 'Brésil',
    phoneCode: '55',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Brasil',
    nationality: 'Brazilian',
    states: {
      AC: 'Acre',
      AL: 'Alagoas',
      AP: 'Amapá',
      AM: 'Amazonas',
      BA: 'Bahia',
      CE: 'Ceará',
      DF: 'Distrito Federal',
      ES: 'Espírito Santo',
      GO: 'Goiás',
      MA: 'Maranhão',
      MT: 'Mato Grosso',
      MS: 'Mato Grosso do Sul',
      MG: 'Minas Gerais',
      PA: 'Pará',
      PB: 'Paraíba',
      PR: 'Paraná',
      PE: 'Pernambuco',
      PI: 'Piauí',
      RJ: 'Rio de Janeiro',
      RN: 'Rio Grande do Norte',
      RS: 'Rio Grande do Sul',
      RO: 'Rondônia',
      RR: 'Roraima',
      SC: 'Santa Catarina',
      SP: 'São Paulo',
      SE: 'Sergipe',
      TO: 'Tocantins'
    }
  },
  io: {
    key3: 'IOT',
    name: 'British Indian Ocean Territory',
    name3: 'British Indian Ocean Territory',
    nameFr: "Indien (le Territoire britannique de l'océan)",
    phoneCode: '246',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Indian (British Indian Ocean Territory)',
    states: {}
  },
  vg: {
    key3: 'VGB',
    name: 'Virgin Islands, British',
    name3: 'Virgin Islands (British)',
    nameFr: 'Vierges britanniques (les Îles)',
    phoneCode: '1',
    areaCode: ['284'],
    phoneOrder: 11,
    localName: '',
    nationality: 'Virgin Islander (Virgin Islands, British)',
    states: {}
  },
  bn: {
    key3: 'BRN',
    name: 'Brunei Darussalam',
    name3: 'Brunei Darussalam',
    nameFr: 'Brunéi Darussalam',
    phoneCode: '673',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Brunei Darussalam',
    nationality: 'Bruneian',
    states: {}
  },
  bg: {
    key3: 'BGR',
    name: 'Bulgaria',
    name3: 'Bulgaria',
    nameFr: 'Bulgarie',
    phoneCode: '359',
    areaCode: [],
    phoneOrder: 0,
    localName: 'България',
    nationality: 'Bulgarian',
    states: {}
  },
  bf: {
    key3: 'BFA',
    name: 'Burkina Faso',
    name3: 'Burkina Faso',
    nameFr: 'Burkina Faso',
    phoneCode: '226',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Burkinabe',
    states: {}
  },
  bi: {
    key3: 'BDI',
    name: 'Burundi',
    name3: 'Burundi',
    nameFr: 'Burundi',
    phoneCode: '257',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Uburundi',
    nationality: 'Burundian',
    states: {}
  },
  kh: {
    key3: 'KHM',
    name: 'Cambodia',
    name3: 'Cambodia',
    nameFr: 'Cambodge',
    phoneCode: '855',
    areaCode: [],
    phoneOrder: 0,
    localName: 'កម្ពុជា',
    nationality: 'Cambodian, Khmer',
    states: {}
  },
  cm: {
    key3: 'CMR',
    name: 'Cameroon',
    name3: 'Cameroon',
    nameFr: 'Cameroun',
    phoneCode: '237',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Cameroun',
    nationality: 'Cameroonian',
    states: {}
  },
  ca: {
    key3: 'CAN',
    name: 'Canada',
    name3: 'Canada',
    nameFr: 'Canada',
    phoneCode: '1',
    areaCode: [
      '204',
      '226',
      '236',
      '249',
      '250',
      '289',
      '306',
      '343',
      '365',
      '387',
      '403',
      '416',
      '418',
      '431',
      '437',
      '438',
      '450',
      '506',
      '514',
      '519',
      '548',
      '579',
      '581',
      '587',
      '604',
      '613',
      '639',
      '647',
      '672',
      '705',
      '709',
      '742',
      '778',
      '780',
      '782',
      '807',
      '819',
      '825',
      '867',
      '873',
      '902',
      '905'
    ],
    phoneOrder: 1,
    localName: '',
    nationality: 'Canadian',
    states: {
      AB: 'Alberta',
      BC: 'British Columbia',
      MB: 'Manitoba',
      NB: 'New Brunswick',
      NL: 'Newfoundland and Labrador',
      NT: 'Northwest Territories',
      NS: 'Nova Scotia',
      NU: 'Nunavut',
      ON: 'Ontario',
      PE: 'Prince Edward Island',
      QC: 'Quebec',
      SK: 'Saskatchewan',
      YT: 'Yukon Territories'
    }
  },
  cv: {
    key3: 'CPV',
    name: 'Cape Verde',
    name3: 'Cabo Verde',
    nameFr: 'Cap-Vert',
    phoneCode: '238',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Kabu Verdi',
    nationality: 'Cape Verdian',
    states: {}
  },
  bq: {
    key3: 'BES',
    name: 'Bonaire, Sint Eustatius and Saba',
    name3: 'Bonaire, Sint Eustatius and Saba',
    nameFr: 'Bonaire, Saint-Eustache et Saba',
    phoneCode: '599',
    areaCode: ['3', '4', '7'],
    phoneOrder: 1,
    localName: 'Bonaire, Sint Eustatius and Saba',
    nationality: 'Dutch (Bonaire, Sint Eustatius and Saba)',
    states: {}
  },
  ky: {
    key3: 'CYM',
    name: 'Cayman Islands',
    name3: 'Cayman Islands',
    nameFr: 'Caïmans (les Îles)',
    phoneCode: '1',
    areaCode: ['345'],
    phoneOrder: 12,
    localName: '',
    nationality: 'Caymanian',
    states: {}
  },
  cf: {
    key3: 'CAF',
    name: 'Central African Republic',
    name3: 'Central African Republic',
    nameFr: 'République centrafricaine',
    phoneCode: '236',
    areaCode: [],
    phoneOrder: 0,
    localName: 'République centrafricaine',
    nationality: 'Central African',
    states: {}
  },
  td: {
    key3: 'TCD',
    name: 'Chad',
    name3: 'Chad',
    nameFr: 'Tchad',
    phoneCode: '235',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Tchad',
    nationality: 'Chadian',
    states: {}
  },
  cl: {
    key3: 'CHL',
    name: 'Chile',
    name3: 'Chile',
    nameFr: 'Chili',
    phoneCode: '56',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Chilean',
    states: {}
  },
  cn: {
    key3: 'CHN',
    name: 'China',
    name3: 'China',
    nameFr: 'Chine',
    phoneCode: '86',
    areaCode: [],
    phoneOrder: 0,
    localName: '中国',
    nationality: 'Chinese (China)',
    states: {
      11: 'Beijing',
      12: 'Tianjin',
      13: 'Hebei',
      14: 'Shanxi',
      15: 'Nei Mongol',
      21: 'Liaoning',
      22: 'Jilin',
      23: 'Heilongjiang',
      31: 'Shanghai',
      32: 'Jiangsu',
      33: 'Zhejiang',
      34: 'Anhui',
      35: 'Fujian',
      36: 'Jiangxi',
      37: 'Shandong',
      41: 'Henan',
      42: 'Hubei',
      43: 'Hunan',
      44: 'Guangdong',
      45: 'Guangxi',
      46: 'Hainan',
      50: 'Chongqing',
      51: 'Sichuan',
      52: 'Guizhou',
      53: 'Yunnan',
      54: 'Xizang',
      61: 'Shaanxi',
      62: 'Gansu',
      63: 'Qinghai',
      64: 'Ningxia',
      65: 'Xinjiang',
      71: 'Chinese Taipei',
      91: 'Hong Kong',
      92: 'Macao'
    }
  },
  cx: {
    key3: 'CXR',
    name: 'Christmas Island',
    name3: 'Christmas Island',
    nameFr: "Christmas (l'Île)",
    phoneCode: '61',
    areaCode: ['89164'],
    phoneOrder: 2,
    localName: '',
    nationality: 'Christmas Islander',
    states: {}
  },
  cc: {
    key3: 'CCK',
    name: 'Cocos (Keeling) Islands',
    name3: 'Cocos (Keeling) Islands',
    nameFr: 'Cocos (les Îles)/ Keeling (les Îles)',
    phoneCode: '61',
    areaCode: ['89162'],
    phoneOrder: 1,
    localName: '',
    nationality: 'Cocos Islander',
    states: {}
  },
  co: {
    key3: 'COL',
    name: 'Colombia',
    name3: 'Colombia',
    nameFr: 'Colombie',
    phoneCode: '57',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Colombian',
    states: {}
  },
  km: {
    key3: 'COM',
    name: 'Comoros',
    name3: 'Comoros',
    nameFr: 'Comores',
    phoneCode: '269',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫جزر القمر‬‎',
    nationality: 'Comoran',
    states: {}
  },
  cd: {
    key3: 'COD',
    name: 'Congo, the Democratic Republic of the',
    name3: 'Congo, Democratic Republic of the',
    nameFr: 'Congo (la République démocratique du)',
    phoneCode: '243',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Jamhuri ya Kidemokrasia ya Kongo',
    nationality: 'Congolese (Congo (DRC))',
    states: {}
  },
  cg: {
    key3: 'COG',
    name: 'Congo',
    name3: 'Congo',
    nameFr: 'Congo',
    phoneCode: '242',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Repubilika ya Kôngo',
    nationality: 'Congolese (Congo (Republic))',
    states: {}
  },
  ck: {
    key3: 'COK',
    name: 'Cook Islands',
    name3: 'Cook Islands',
    nameFr: 'Cook (les Îles)',
    phoneCode: '682',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Cook Islander',
    states: {}
  },
  cr: {
    key3: 'CRI',
    name: 'Costa Rica',
    name3: 'Costa Rica',
    nameFr: 'Costa Rica',
    phoneCode: '506',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Costa Rican',
    states: {}
  },
  ci: {
    key3: 'CIV',
    name: "Cote d'Ivoire",
    name3: "Côte d'Ivoire",
    nameFr: "Côte d'Ivoire",
    phoneCode: '225',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Côte d’Ivoire',
    nationality: 'Ivorian',
    states: {}
  },
  hr: {
    key3: 'HRV',
    name: 'Croatia',
    name3: 'Croatia',
    nameFr: 'Croatie',
    phoneCode: '385',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Hrvatska',
    nationality: 'Croatian',
    states: {}
  },
  cu: {
    key3: 'CUB',
    name: 'Cuba',
    name3: 'Cuba',
    nameFr: 'Cuba',
    phoneCode: '53',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Cuban',
    states: {}
  },
  cw: {
    key3: 'CUW',
    name: 'Curaçao',
    name3: 'Curaçao',
    nameFr: 'Curaçao',
    phoneCode: '599',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Curaçao',
    nationality: 'Dutch (Curaçaoan)',
    states: {}
  },
  cy: {
    key3: 'CYP',
    name: 'Cyprus',
    name3: 'Cyprus',
    nameFr: 'Chypre',
    phoneCode: '357',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Κύπρος',
    nationality: 'Cypriot',
    states: {}
  },
  cz: {
    key3: 'CZE',
    name: 'Czech Republic',
    name3: 'Czechia',
    nameFr: 'Tchéquie',
    phoneCode: '420',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Česká republika',
    nationality: 'Czech',
    states: {}
  },
  dk: {
    key3: 'DNK',
    name: 'Denmark',
    name3: 'Denmark',
    nameFr: 'Danemark',
    phoneCode: '45',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Danmark',
    nationality: 'Danish, Dane',
    states: {}
  },
  dj: {
    key3: 'DJI',
    name: 'Djibouti',
    name3: 'Djibouti',
    nameFr: 'Djibouti',
    phoneCode: '253',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Djiboutian',
    states: {}
  },
  dm: {
    key3: 'DMA',
    name: 'Dominica',
    name3: 'Dominica',
    nameFr: 'Dominique',
    phoneCode: '1',
    areaCode: ['767'],
    phoneOrder: 13,
    localName: '',
    nationality: 'Dominican (Dominica)',
    states: {}
  },
  do: {
    key3: 'DOM',
    name: 'Dominican Republic',
    name3: 'Dominican Republic',
    nameFr: 'Dominicaine (la République)',
    phoneCode: '1',
    areaCode: ['809', '829', '849'],
    phoneOrder: 2,
    localName: 'República Dominicana',
    nationality: 'Dominican (Dominican Republic)',
    states: {}
  },
  ec: {
    key3: 'ECU',
    name: 'Ecuador',
    name3: 'Ecuador',
    nameFr: 'Équateur',
    phoneCode: '593',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Ecuadorean',
    states: {}
  },
  eg: {
    key3: 'EGY',
    name: 'Egypt',
    name3: 'Egypt',
    nameFr: 'Égypte',
    phoneCode: '20',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫مصر‬‎',
    nationality: 'Egyptian',
    states: {}
  },
  sv: {
    key3: 'SLV',
    name: 'El Salvador',
    name3: 'El Salvador',
    nameFr: 'El Salvador',
    phoneCode: '503',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Salvadoran',
    states: {}
  },
  gq: {
    key3: 'GNQ',
    name: 'Equatorial Guinea',
    name3: 'Equatorial Guinea',
    nameFr: 'Guinée équatoriale',
    phoneCode: '240',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Guinea Ecuatorial',
    nationality: 'Equatorial Guinean',
    states: {}
  },
  er: {
    key3: 'ERI',
    name: 'Eritrea',
    name3: 'Eritrea',
    nameFr: 'Érythrée',
    phoneCode: '291',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Eritrean',
    states: {}
  },
  ee: {
    key3: 'EST',
    name: 'Estonia',
    name3: 'Estonia',
    nameFr: 'Estonie',
    phoneCode: '372',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Eesti',
    nationality: 'Estonian',
    states: {}
  },
  et: {
    key3: 'ETH',
    name: 'Ethiopia',
    name3: 'Ethiopia',
    nameFr: 'Éthiopie',
    phoneCode: '251',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Ethiopian',
    states: {}
  },
  fk: {
    key3: 'FLK',
    name: 'Falkland Islands (Malvinas)',
    name3: 'Falkland Islands (Malvinas)',
    nameFr: 'Falkland (les Îles)/Malouines (les Îles)',
    phoneCode: '500',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Islas Malvinas',
    nationality: 'Falkland Islander',
    states: {}
  },
  fo: {
    key3: 'FRO',
    name: 'Faroe Islands',
    name3: 'Faroe Islands',
    nameFr: 'Féroé (les Îles)',
    phoneCode: '298',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Føroyar',
    nationality: 'Faroe Islander, Faroese',
    states: {}
  },
  fj: {
    key3: 'FJI',
    name: 'Fiji',
    name3: 'Fiji',
    nameFr: 'Fidji',
    phoneCode: '679',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Fijian',
    states: {}
  },
  fi: {
    key3: 'FIN',
    name: 'Finland',
    name3: 'Finland',
    nameFr: 'Finlande',
    phoneCode: '358',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Suomi',
    nationality: 'Finnish',
    states: {}
  },
  fr: {
    key3: 'FRA',
    name: 'France',
    name3: 'France',
    nameFr: 'France',
    phoneCode: '33',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'French (France)',
    states: {}
  },
  tf: {
    key3: 'ATF',
    name: 'French Southern Territories',
    name3: 'French Southern Territories',
    nameFr: 'Terres australes françaises',
    phoneCode: '',
    localName: '',
    nationality: 'French (French Southern Territories)',
    states: {}
  },
  gf: {
    key3: 'GUF',
    name: 'French Guiana',
    name3: 'French Guiana',
    nameFr: 'Guyane française (la)',
    phoneCode: '594',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Guyane française',
    nationality: 'French Guiana',
    states: {}
  },
  pf: {
    key3: 'PYF',
    name: 'French Polynesia',
    name3: 'French Polynesia',
    nameFr: 'Polynésie française',
    phoneCode: '689',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Polynésie française',
    nationality: 'French Polynesian',
    states: {}
  },
  ga: {
    key3: 'GAB',
    name: 'Gabon',
    name3: 'Gabon',
    nameFr: 'Gabon',
    phoneCode: '241',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Gabonese',
    states: {}
  },
  gm: {
    key3: 'GMB',
    name: 'Gambia',
    name3: 'Gambia',
    nameFr: 'Gambie',
    phoneCode: '220',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Gambian',
    states: {}
  },
  ge: {
    key3: 'GEO',
    name: 'Georgia',
    name3: 'Georgia',
    nameFr: 'Géorgie',
    phoneCode: '995',
    areaCode: [],
    phoneOrder: 0,
    localName: 'საქართველო',
    nationality: 'Georgian',
    states: {}
  },
  de: {
    key3: 'DEU',
    name: 'Germany',
    name3: 'Germany',
    nameFr: 'Allemagne',
    phoneCode: '49',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Deutschland',
    nationality: 'German',
    states: {}
  },
  gh: {
    key3: 'GHA',
    name: 'Ghana',
    name3: 'Ghana',
    nameFr: 'Ghana',
    phoneCode: '233',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Gaana',
    nationality: 'Ghanaian',
    states: {}
  },
  gi: {
    key3: 'GIB',
    name: 'Gibraltar',
    name3: 'Gibraltar',
    nameFr: 'Gibraltar',
    phoneCode: '350',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Gibraltarian',
    states: {}
  },
  gr: {
    key3: 'GRC',
    name: 'Greece',
    name3: 'Greece',
    nameFr: 'Grèce',
    phoneCode: '30',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Ελλάδα',
    nationality: 'Greek',
    states: {}
  },
  gl: {
    key3: 'GRL',
    name: 'Greenland',
    name3: 'Greenland',
    nameFr: 'Groenland',
    phoneCode: '299',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Kalaallit Nunaat',
    nationality: 'Greenlandic',
    states: {}
  },
  gd: {
    key3: 'GRD',
    name: 'Grenada',
    name3: 'Grenada',
    nameFr: 'Grenade',
    phoneCode: '1',
    areaCode: ['473'],
    phoneOrder: 14,
    localName: '',
    nationality: 'Grenadian',
    states: {}
  },
  gp: {
    key3: 'GLP',
    name: 'Guadeloupe',
    name3: 'Guadeloupe',
    nameFr: 'Guadeloupe',
    phoneCode: '590',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Guadeloupian',
    states: {}
  },
  gt: {
    key3: 'GTM',
    name: 'Guatemala',
    name3: 'Guatemala',
    nameFr: 'Guatemala',
    phoneCode: '502',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Guatemalan',
    states: {}
  },
  gg: {
    key3: 'GGY',
    name: 'Guernsey',
    name3: 'Guernsey',
    nameFr: 'Guernesey',
    phoneCode: '44',
    areaCode: ['1481', '7781', '7839', '7911'],
    phoneOrder: 1,
    localName: '',
    nationality: 'Channel Islander (Guernsey)',
    states: {}
  },
  gn: {
    key3: 'GIN',
    name: 'Guinea',
    name3: 'Guinea',
    nameFr: 'Guinée',
    phoneCode: '224',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Guinée',
    nationality: 'Guinean',
    states: {}
  },
  gw: {
    key3: 'GNB',
    name: 'Guinea-Bissau',
    name3: 'Guinea-Bissau',
    nameFr: 'Guinée-Bissau',
    phoneCode: '245',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Guiné-Bissau',
    nationality: 'Bissau-Guinean',
    states: {}
  },
  gy: {
    key3: 'GUY',
    name: 'Guyana',
    name3: 'Guyana',
    nameFr: 'Guyana',
    phoneCode: '592',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Guyanese',
    states: {}
  },
  ht: {
    key3: 'HTI',
    name: 'Haiti',
    name3: 'Haiti',
    nameFr: 'Haïti',
    phoneCode: '509',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Haitian',
    states: {}
  },
  hm: {
    key3: 'HMD',
    name: 'Heard Island and McDonald Islands',
    name3: 'Heard Island and McDonald Islands',
    nameFr: "Heard-et-Îles MacDonald (l'Île)",
    phoneCode: '',
    localName: '',
    nationality: 'Heard and McDonald Islander',
    states: {}
  },
  hn: {
    key3: 'HND',
    name: 'Honduras',
    name3: 'Honduras',
    nameFr: 'Honduras',
    phoneCode: '504',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Honduran',
    states: {}
  },
  hu: {
    key3: 'HUN',
    name: 'Hungary',
    name3: 'Hungary',
    nameFr: 'Hongrie',
    phoneCode: '36',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Magyarország',
    nationality: 'Hungarian',
    states: {}
  },
  is: {
    key3: 'ISL',
    name: 'Iceland',
    name3: 'Iceland',
    nameFr: 'Islande',
    phoneCode: '354',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Ísland',
    nationality: 'Icelander',
    states: {}
  },
  in: {
    key3: 'IND',
    name: 'India',
    name3: 'India',
    nameFr: 'Inde',
    phoneCode: '91',
    areaCode: [],
    phoneOrder: 0,
    localName: 'भारत',
    nationality: 'Indian (India)',
    states: {
      AN: 'Andaman and Nicobar Islands',
      AP: 'Andhra Pradesh',
      AR: 'Arunachal Pradesh',
      AS: 'Assam',
      BR: 'Bihar',
      CH: 'Chandigarh',
      CT: 'Chhattisgarh',
      DN: 'Dadra and Nagar Haveli',
      DD: 'Daman and Diu',
      DL: 'Delhi',
      GA: 'Goa',
      GJ: 'Gujarat',
      HR: 'Haryana',
      HP: 'Himachal Pradesh',
      JK: 'Jammu and Kashmir',
      JH: 'Jharkhand',
      KA: 'Karnataka',
      KL: 'Kerala',
      LD: 'Lakshadweep',
      MP: 'Madhya Pradesh',
      MH: 'Maharashtra',
      MN: 'Manipur',
      ML: 'Meghalaya',
      MZ: 'Mizoram',
      NL: 'Nagaland',
      OR: 'Odisha',
      PY: 'Puducherry',
      PB: 'Punjab',
      RJ: 'Rajasthan',
      SK: 'Sikkim',
      TN: 'Tamil Nadu',
      TR: 'Tripura',
      UT: 'Uttarakhand',
      UP: 'Uttar Pradesh',
      WB: 'West Bengal'
    }
  },
  id: {
    key3: 'IDN',
    name: 'Indonesia',
    name3: 'Indonesia',
    nameFr: 'Indonésie',
    phoneCode: '62',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Indonesian',
    states: {}
  },
  ir: {
    key3: 'IRN',
    name: 'Iran, Islamic Republic of',
    name3: 'Iran (Islamic Republic of)',
    nameFr: "Iran (République Islamique d')",
    phoneCode: '98',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫ایران‬‎',
    nationality: 'Iranian',
    states: {}
  },
  iq: {
    key3: 'IRQ',
    name: 'Iraq',
    name3: 'Iraq',
    nameFr: 'Iraq',
    phoneCode: '964',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫العراق‬‎',
    nationality: 'Iraqi',
    states: {}
  },
  ie: {
    key3: 'IRL',
    name: 'Ireland',
    name3: 'Ireland',
    nameFr: 'Irlande',
    phoneCode: '353',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Éire',
    nationality: 'Irish',
    states: {
      CW: 'Carlow',
      CN: 'Cavan',
      CE: 'Clare',
      CO: 'Cork',
      DL: 'Donegal',
      D: 'Dublin',
      G: 'Galway',
      KY: 'Kerry',
      KE: 'Kildare',
      KK: 'Kilkenny',
      LS: 'Laois',
      LM: 'Leitrim',
      LK: 'Limerick',
      LD: 'Longford',
      LH: 'Louth',
      MO: 'Mayo',
      MH: 'Meath',
      MN: 'Monaghan',
      OY: 'Offaly',
      RN: 'Roscommon',
      SO: 'Sligo',
      TA: 'Tipperary',
      WD: 'Waterford',
      WH: 'Westmeath',
      WX: 'Wexford',
      WW: 'Wicklow'
    }
  },
  im: {
    key3: 'IMN',
    name: 'Isle of Man',
    name3: 'Isle of Man',
    nameFr: 'Île de Man',
    phoneCode: '44',
    areaCode: ['1624', '74576', '7524', '7924', '7624'],
    phoneOrder: 2,
    localName: '',
    nationality: 'Manx',
    states: {}
  },
  il: {
    key3: 'ISR',
    name: 'Israel',
    name3: 'Israel',
    nameFr: 'Israël',
    phoneCode: '972',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫ישראל‬‎',
    nationality: 'Israeli',
    states: {}
  },
  it: {
    key3: 'ITA',
    name: 'Italy',
    name3: 'Italy',
    nameFr: 'Italie',
    phoneCode: '39',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Italia',
    nationality: 'Italian (Italy)',
    states: {
      AG: 'Agrigento',
      AL: 'Alessandria',
      AN: 'Ancona',
      AO: 'Aosta',
      AP: 'Ascoli Piceno',
      AQ: "L'Aquila",
      AR: 'Arezzo',
      AT: 'Asti',
      AV: 'Avellino',
      BA: 'Bari',
      BG: 'Bergamo',
      BI: 'Biella',
      BL: 'Belluno',
      BN: 'Benevento',
      BO: 'Bologna',
      BR: 'Brindisi',
      BS: 'Brescia',
      BT: 'Barletta-Andria-Trani',
      BZ: 'Bolzano',
      CA: 'Cagliari',
      CB: 'Campobasso',
      CE: 'Caserta',
      CH: 'Chieti',
      CI: 'Carbonia-Iglesias',
      CL: 'Caltanissetta',
      CN: 'Cuneo',
      CO: 'Como',
      CR: 'Cremona',
      CS: 'Cosenza',
      CT: 'Catania',
      CZ: 'Catanzaro',
      EN: 'Enna',
      FC: 'Forlì-Cesena',
      FE: 'Ferrara',
      FG: 'Foggia',
      FI: 'Florence',
      FM: 'Fermo',
      FR: 'Frosinone',
      GE: 'Genoa',
      GO: 'Gorizia',
      GR: 'Grosseto',
      IM: 'Imperia',
      IS: 'Isernia',
      KR: 'Crotone',
      LC: 'Lecco',
      LE: 'Lecce',
      LI: 'Livorno',
      LO: 'Lodi',
      LT: 'Latina',
      LU: 'Lucca',
      MB: 'Monza and Brianza',
      MC: 'Macerata',
      ME: 'Messina',
      MI: 'Milan',
      MN: 'Mantua',
      MO: 'Modena',
      MS: 'Massa and Carrara',
      MT: 'Matera',
      NA: 'Naples',
      NO: 'Novara',
      NU: 'Nuoro',
      OG: 'Ogliastra',
      OR: 'Oristano',
      OT: 'Olbia-Tempio',
      PA: 'Palermo',
      PC: 'Piacenza',
      PD: 'Padua',
      PE: 'Pescara',
      PG: 'Perugia',
      PI: 'Pisa',
      PN: 'Pordenone',
      PO: 'Prato',
      PR: 'Parma',
      PT: 'Pistoia',
      PU: 'Pesaro and Urbino',
      PV: 'Pavia',
      PZ: 'Potenza',
      RA: 'Ravenna',
      RC: 'Reggio Calabria',
      RE: 'Reggio Emilia',
      RG: 'Ragusa',
      RI: 'Rieti',
      RM: 'Rome',
      RN: 'Rimini',
      RO: 'Rovigo',
      SA: 'Salerno',
      SI: 'Siena',
      SO: 'Sondrio',
      SP: 'La Spezia',
      SR: 'Syracuse',
      SS: 'Sassari',
      SV: 'Savona',
      TA: 'Taranto',
      TE: 'Teramo',
      TN: 'Trento',
      TO: 'Turin',
      TP: 'Trapani',
      TR: 'Terni',
      TS: 'Trieste',
      TV: 'Treviso',
      UD: 'Udine',
      VA: 'Varese',
      VB: 'Verbano-Cusio-Ossola',
      VC: 'Vercelli',
      VE: 'Venice',
      VI: 'Vicenza',
      VR: 'Verona',
      VS: 'Medio Campidano',
      VT: 'Viterbo',
      VV: 'Vibo Valentia'
    }
  },
  jm: {
    key3: 'JAM',
    name: 'Jamaica',
    name3: 'Jamaica',
    nameFr: 'Jamaïque',
    phoneCode: '1',
    areaCode: ['876', '658'],
    phoneOrder: 4,
    localName: '',
    nationality: 'Jamaican',
    states: {}
  },
  jp: {
    key3: 'JPN',
    name: 'Japan',
    name3: 'Japan',
    nameFr: 'Japon',
    phoneCode: '81',
    areaCode: [],
    phoneOrder: 0,
    localName: '日本',
    nationality: 'Japanese',
    states: {}
  },
  je: {
    key3: 'JEY',
    name: 'Jersey',
    name3: 'Jersey',
    nameFr: 'Jersey',
    phoneCode: '44',
    areaCode: ['1534', '7509', '7700', '7797', '7829', '7937'],
    phoneOrder: 3,
    localName: '',
    nationality: 'Channel Islander (Jersey)',
    states: {}
  },
  jo: {
    key3: 'JOR',
    name: 'Jordan',
    name3: 'Jordan',
    nameFr: 'Jordanie',
    phoneCode: '962',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫الأردن‬‎',
    nationality: 'Jordanian',
    states: {}
  },
  kz: {
    key3: 'KAZ',
    name: 'Kazakhstan',
    name3: 'Kazakhstan',
    nameFr: 'Kazakhstan',
    phoneCode: '7',
    areaCode: ['33', '7'],
    phoneOrder: 1,
    localName: 'Қазақстан',
    nationality: 'Kazakhstani',
    states: {}
  },
  ke: {
    key3: 'KEN',
    name: 'Kenya',
    name3: 'Kenya',
    nameFr: 'Kenya',
    phoneCode: '254',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Kenyan',
    states: {}
  },
  ki: {
    key3: 'KIR',
    name: 'Kiribati',
    name3: 'Kiribati',
    nameFr: 'Kiribati',
    phoneCode: '686',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'I-Kiribati, Kiribatian',
    states: {}
  },
  kw: {
    key3: 'KWT',
    name: 'Kuwait',
    name3: 'Kuwait',
    nameFr: 'Koweït',
    phoneCode: '965',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫الكويت‬‎',
    nationality: 'Kuwaiti',
    states: {}
  },
  kg: {
    key3: 'KGZ',
    name: 'Kyrgyzstan',
    name3: 'Kyrgyzstan',
    nameFr: 'Kirghizistan',
    phoneCode: '996',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Кыргызстан',
    nationality: 'Kyrgyzstani, Kyrgyz',
    states: {}
  },
  la: {
    key3: 'LAO',
    name: "Lao People's Democratic Republic",
    name3: "Lao People's Democratic Republic",
    nameFr: 'Lao (la République démocratique populaire)',
    phoneCode: '856',
    areaCode: [],
    phoneOrder: 0,
    localName: 'ລາວ',
    nationality: 'Laotian',
    states: {}
  },
  lv: {
    key3: 'LVA',
    name: 'Latvia',
    name3: 'Latvia',
    nameFr: 'Lettonie',
    phoneCode: '371',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Latvija',
    nationality: 'Latvian',
    states: {}
  },
  lb: {
    key3: 'LBN',
    name: 'Lebanon',
    name3: 'Lebanon',
    nameFr: 'Liban',
    phoneCode: '961',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫لبنان‬‎',
    nationality: 'Lebanese',
    states: {}
  },
  ls: {
    key3: 'LSO',
    name: 'Lesotho',
    name3: 'Lesotho',
    nameFr: 'Lesotho',
    phoneCode: '266',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Mosotho',
    states: {}
  },
  lr: {
    key3: 'LBR',
    name: 'Liberia',
    name3: 'Liberia',
    nameFr: 'Libéria',
    phoneCode: '231',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Liberian',
    states: {}
  },
  ly: {
    key3: 'LBY',
    name: 'Libyan Arab Jamahiriya',
    name3: 'Libya',
    nameFr: 'Libye',
    phoneCode: '218',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫ليبيا‬‎',
    nationality: 'Libyan',
    states: {}
  },
  li: {
    key3: 'LIE',
    name: 'Liechtenstein',
    name3: 'Liechtenstein',
    nameFr: 'Liechtenstein',
    phoneCode: '423',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Liechtensteiner',
    states: {}
  },
  lt: {
    key3: 'LTU',
    name: 'Lithuania',
    name3: 'Lithuania',
    nameFr: 'Lituanie',
    phoneCode: '370',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Lietuva',
    nationality: 'Lithuanian',
    states: {}
  },
  lu: {
    key3: 'LUX',
    name: 'Luxembourg',
    name3: 'Luxembourg',
    nameFr: 'Luxembourg',
    phoneCode: '352',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Luxembourger',
    states: {}
  },
  mo: {
    key3: 'MAC',
    name: 'Macao',
    name3: 'Macao',
    nameFr: 'Macao',
    phoneCode: '853',
    areaCode: [],
    phoneOrder: 0,
    localName: '澳門',
    nationality: 'Chinese (Macao)',
    states: {}
  },
  mk: {
    key3: 'MKD',
    name: 'Macedonia, the former Yugoslav Republic of',
    name3: 'North Macedonia',
    nameFr: 'Macédoine du Nord',
    phoneCode: '389',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Македонија',
    nationality: 'Macedonian',
    states: {}
  },
  mg: {
    key3: 'MDG',
    name: 'Madagascar',
    name3: 'Madagascar',
    nameFr: 'Madagascar',
    phoneCode: '261',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Madagasikara',
    nationality: 'Malagasy',
    states: {}
  },
  mw: {
    key3: 'MWI',
    name: 'Malawi',
    name3: 'Malawi',
    nameFr: 'Malawi',
    phoneCode: '265',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Malawian',
    states: {}
  },
  my: {
    key3: 'MYS',
    name: 'Malaysia',
    name3: 'Malaysia',
    nameFr: 'Malaisie',
    phoneCode: '60',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Malaysian',
    states: {}
  },
  mv: {
    key3: 'MDV',
    name: 'Maldives',
    name3: 'Maldives',
    nameFr: 'Maldives',
    phoneCode: '960',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Maldivan',
    states: {}
  },
  ml: {
    key3: 'MLI',
    name: 'Mali',
    name3: 'Mali',
    nameFr: 'Mali',
    phoneCode: '223',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Malian',
    states: {}
  },
  mt: {
    key3: 'MLT',
    name: 'Malta',
    name3: 'Malta',
    nameFr: 'Malte',
    phoneCode: '356',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Maltese',
    states: {}
  },
  mq: {
    key3: 'MTQ',
    name: 'Martinique',
    name3: 'Martinique',
    nameFr: 'Martinique',
    phoneCode: '596',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'French (Martinique)',
    states: {}
  },
  mr: {
    key3: 'MRT',
    name: 'Mauritania',
    name3: 'Mauritania',
    nameFr: 'Mauritanie',
    phoneCode: '222',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫موريتانيا‬‎',
    nationality: 'Mauritanian',
    states: {}
  },
  mu: {
    key3: 'MUS',
    name: 'Mauritius',
    name3: 'Mauritius',
    nameFr: 'Maurice',
    phoneCode: '230',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Moris',
    nationality: 'Mauritian',
    states: {}
  },
  yt: {
    key3: 'MYT',
    name: 'Mayotte',
    name3: 'Mayotte',
    nameFr: 'Mayotte',
    phoneCode: '262',
    areaCode: ['269', '639'],
    phoneOrder: 1,
    localName: '',
    nationality: 'French (Mayotte)',
    states: {}
  },
  mx: {
    key3: 'MEX',
    name: 'Mexico',
    name3: 'Mexico',
    nameFr: 'Mexique',
    phoneCode: '52',
    areaCode: [],
    phoneOrder: 0,
    localName: 'México',
    nationality: 'Mexican',
    states: {
      AG: 'Aguascalientes',
      BC: 'Baja California',
      BS: 'Baja California Sur',
      CH: 'Chihuahua',
      CL: 'Colima',
      CM: 'Campeche',
      CO: 'Coahuila',
      CS: 'Chiapas',
      DF: 'Federal District',
      DG: 'Durango',
      GR: 'Guerrero',
      GT: 'Guanajuato',
      HG: 'Hidalgo',
      JA: 'Jalisco',
      ME: 'Mexico State',
      MI: 'Michoacán',
      MO: 'Morelos',
      NA: 'Nayarit',
      NL: 'Nuevo León',
      OA: 'Oaxaca',
      PB: 'Puebla',
      QE: 'Querétaro',
      QR: 'Quintana Roo',
      SI: 'Sinaloa',
      SL: 'San Luis Potosí',
      SO: 'Sonora',
      TB: 'Tabasco',
      TL: 'Tlaxcala',
      TM: 'Tamaulipas',
      VE: 'Veracruz',
      YU: 'Yucatán',
      ZA: 'Zacatecas'
    }
  },
  md: {
    key3: 'MDA',
    name: 'Moldova, Republic of',
    name3: 'Moldova, Republic of',
    nameFr: 'Moldova (la République de)',
    phoneCode: '373',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Republica Moldova',
    nationality: 'Moldovan',
    states: {}
  },
  mc: {
    key3: 'MCO',
    name: 'Monaco',
    name3: 'Monaco',
    nameFr: 'Monaco',
    phoneCode: '377',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Monégasque',
    states: {}
  },
  mn: {
    key3: 'MNG',
    name: 'Mongolia',
    name3: 'Mongolia',
    nameFr: 'Mongolie',
    phoneCode: '976',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Монгол',
    nationality: 'Mongolian',
    states: {}
  },
  me: {
    key3: 'MNE',
    name: 'Montenegro',
    name3: 'Montenegro',
    nameFr: 'Monténégro',
    phoneCode: '382',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Crna Gora',
    nationality: 'Montenegrin',
    states: {}
  },
  ms: {
    key3: 'MSR',
    name: 'Montserrat',
    name3: 'Montserrat',
    nameFr: 'Montserrat',
    phoneCode: '1',
    areaCode: ['664'],
    phoneOrder: 16,
    localName: '',
    nationality: 'Montserratian',
    states: {}
  },
  ma: {
    key3: 'MAR',
    name: 'Morocco',
    name3: 'Morocco',
    nameFr: 'Maroc',
    phoneCode: '212',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫المغرب‬‎',
    nationality: 'Moroccan',
    states: {}
  },
  mz: {
    key3: 'MOZ',
    name: 'Mozambique',
    name3: 'Mozambique',
    nameFr: 'Mozambique',
    phoneCode: '258',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Moçambique',
    nationality: 'Mozambican',
    states: {}
  },
  mm: {
    key3: 'MMR',
    name: 'Myanmar',
    name3: 'Myanmar',
    nameFr: 'Myanmar',
    phoneCode: '95',
    areaCode: [],
    phoneOrder: 0,
    localName: 'မြန်မာ',
    nationality: 'Burmese, Myanma',
    states: {}
  },
  na: {
    key3: 'NAM',
    name: 'Namibia',
    name3: 'Namibia',
    nameFr: 'Namibie',
    phoneCode: '264',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Namibië',
    nationality: 'Namibian',
    states: {}
  },
  nr: {
    key3: 'NRU',
    name: 'Nauru',
    name3: 'Nauru',
    nameFr: 'Nauru',
    phoneCode: '674',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Nauruan',
    states: {}
  },
  np: {
    key3: 'NPL',
    name: 'Nepal',
    name3: 'Nepal',
    nameFr: 'Népal',
    phoneCode: '977',
    areaCode: [],
    phoneOrder: 0,
    localName: 'नेपाल',
    nationality: 'Nepalese',
    states: {}
  },
  nl: {
    key3: 'NLD',
    name: 'Netherlands',
    name3: 'Netherlands',
    nameFr: 'Pays-Bas',
    phoneCode: '31',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Nederland',
    nationality: 'Dutch (Netherlands)',
    states: {}
  },
  nc: {
    key3: 'NCL',
    name: 'New Caledonia',
    name3: 'New Caledonia',
    nameFr: 'Nouvelle-Calédonie',
    phoneCode: '687',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Nouvelle-Calédonie',
    nationality: 'New Caledonian',
    states: {}
  },
  nz: {
    key3: 'NZL',
    name: 'New Zealand',
    name3: 'New Zealand',
    nameFr: 'Nouvelle-Zélande',
    phoneCode: '64',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'New Zealander',
    states: {}
  },
  ni: {
    key3: 'NIC',
    name: 'Nicaragua',
    name3: 'Nicaragua',
    nameFr: 'Nicaragua',
    phoneCode: '505',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Nicaraguan',
    states: {}
  },
  ne: {
    key3: 'NER',
    name: 'Niger',
    name3: 'Niger',
    nameFr: 'Niger',
    phoneCode: '227',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Nijar',
    nationality: 'Nigerien (Niger)',
    states: {}
  },
  ng: {
    key3: 'NGA',
    name: 'Nigeria',
    name3: 'Nigeria',
    nameFr: 'Nigéria',
    phoneCode: '234',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Nigerian (Nigeria)',
    states: {}
  },
  nu: {
    key3: 'NIU',
    name: 'Niue',
    name3: 'Niue',
    nameFr: 'Niue',
    phoneCode: '683',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Niuean',
    states: {}
  },
  nf: {
    key3: 'NFK',
    name: 'Norfolk Island',
    name3: 'Norfolk Island',
    nameFr: "Norfolk (l'Île)",
    phoneCode: '672',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Norfolk Islander',
    states: {}
  },
  kp: {
    key3: 'PRK',
    name: "Korea, Democratic People's Republic of",
    name3: "Korea (Democratic People's Republic of)",
    nameFr: 'Corée (la République populaire démocratique de)',
    phoneCode: '850',
    areaCode: [],
    phoneOrder: 0,
    localName: '조선 민주주의 인민 공화국',
    nationality: 'North Korean',
    states: {}
  },
  no: {
    key3: 'NOR',
    name: 'Norway',
    name3: 'Norway',
    nameFr: 'Norvège',
    phoneCode: '47',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Norge',
    nationality: 'Norwegian (Norway)',
    states: {}
  },
  om: {
    key3: 'OMN',
    name: 'Oman',
    name3: 'Oman',
    nameFr: 'Oman',
    phoneCode: '968',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫عُمان‬‎',
    nationality: 'Omani',
    states: {}
  },
  pk: {
    key3: 'PAK',
    name: 'Pakistan',
    name3: 'Pakistan',
    nameFr: 'Pakistan',
    phoneCode: '92',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫پاکستان‬‎',
    nationality: 'Pakistani',
    states: {}
  },
  ps: {
    key3: 'PSE',
    name: 'Palestinian Territory, Occupied',
    name3: 'Palestine, State of',
    nameFr: 'Palestine, État de',
    phoneCode: '970',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫فلسطين‬‎',
    nationality: 'Palestinian',
    states: {}
  },
  pa: {
    key3: 'PAN',
    name: 'Panama',
    name3: 'Panama',
    nameFr: 'Panama',
    phoneCode: '507',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Panamá',
    nationality: 'Panamanian',
    states: {}
  },
  pg: {
    key3: 'PNG',
    name: 'Papua New Guinea',
    name3: 'Papua New Guinea',
    nameFr: 'Papouasie-Nouvelle-Guinée',
    phoneCode: '675',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Papua New Guinean',
    states: {}
  },
  py: {
    key3: 'PRY',
    name: 'Paraguay',
    name3: 'Paraguay',
    nameFr: 'Paraguay',
    phoneCode: '595',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Paraguayan',
    states: {}
  },
  pe: {
    key3: 'PER',
    name: 'Peru',
    name3: 'Peru',
    nameFr: 'Pérou',
    phoneCode: '51',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Perú',
    nationality: 'Peruvian',
    states: {}
  },
  ph: {
    key3: 'PHL',
    name: 'Philippines',
    name3: 'Philippines',
    nameFr: 'Philippines',
    phoneCode: '63',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Filipino',
    states: {}
  },
  pl: {
    key3: 'POL',
    name: 'Poland',
    name3: 'Poland',
    nameFr: 'Pologne',
    phoneCode: '48',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Polska',
    nationality: 'Polish',
    states: {}
  },
  pt: {
    key3: 'PRT',
    name: 'Portugal',
    name3: 'Portugal',
    nameFr: 'Portugal',
    phoneCode: '351',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Portuguese',
    states: {}
  },
  pn: {
    key3: 'PCN',
    name: 'Pitcairn',
    name3: 'Pitcairn',
    nameFr: 'Pitcairn',
    phoneCode: '',
    localName: '',
    nationality: 'Pitcairn Islander',
    states: {}
  },
  qa: {
    key3: 'QAT',
    name: 'Qatar',
    name3: 'Qatar',
    nameFr: 'Qatar',
    phoneCode: '974',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫قطر‬‎',
    nationality: 'Qatari',
    states: {}
  },
  re: {
    key3: 'REU',
    name: 'Reunion',
    name3: 'Réunion',
    nameFr: 'Réunion',
    phoneCode: '262',
    areaCode: [],
    phoneOrder: 0,
    localName: 'La Réunion',
    nationality: 'French (Réunionese)',
    states: {}
  },
  ro: {
    key3: 'ROU',
    name: 'Romania',
    name3: 'Romania',
    nameFr: 'Roumanie',
    phoneCode: '40',
    areaCode: [],
    phoneOrder: 0,
    localName: 'România',
    nationality: 'Romanian',
    states: {}
  },
  ru: {
    key3: 'RUS',
    name: 'Russian Federation',
    name3: 'Russian Federation',
    nameFr: 'Russie (la Fédération de)',
    phoneCode: '7',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Россия',
    nationality: 'Russian',
    states: {}
  },
  rw: {
    key3: 'RWA',
    name: 'Rwanda',
    name3: 'Rwanda',
    nameFr: 'Rwanda',
    phoneCode: '250',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Rwandan',
    states: {}
  },
  bl: {
    key3: 'BLM',
    name: 'Saint Barthélemy',
    name3: 'Saint Barthélemy',
    nameFr: 'Saint-Barthélemy',
    phoneCode: '590',
    areaCode: [],
    phoneOrder: 1,
    localName: 'Saint-Barthélemy',
    nationality: 'Barthélemois, Saint-Barth',
    states: {}
  },
  sh: {
    key3: 'SHN',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    name3: 'Saint Helena, Ascension and Tristan da Cunha',
    nameFr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    phoneCode: '290',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Saint Helenian',
    states: {}
  },
  kn: {
    key3: 'KNA',
    name: 'Saint Kitts and Nevis',
    name3: 'Saint Kitts and Nevis',
    nameFr: 'Saint-Kitts-et-Nevis',
    phoneCode: '1',
    areaCode: ['869'],
    phoneOrder: 18,
    localName: '',
    nationality: 'Kittitian or Nevisian',
    states: {}
  },
  lc: {
    key3: 'LCA',
    name: 'Saint Lucia',
    name3: 'Saint Lucia',
    nameFr: 'Sainte-Lucie',
    phoneCode: '1',
    areaCode: ['758'],
    phoneOrder: 19,
    localName: '',
    nationality: 'Saint Lucian',
    states: {}
  },
  mf: {
    key3: 'MAF',
    name: 'Saint Martin (French part)',
    name3: 'Saint Martin (French part)',
    nameFr: 'Saint-Martin (partie française)',
    phoneCode: '590',
    areaCode: [],
    phoneOrder: 2,
    localName: 'Saint-Martin (partie française)',
    nationality: 'Saint Martin Islander',
    states: {}
  },
  pm: {
    key3: 'SPM',
    name: 'Saint Pierre and Miquelon',
    name3: 'Saint Pierre and Miquelon',
    nameFr: 'Saint-Pierre-et-Miquelon',
    phoneCode: '508',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Saint-Pierre-et-Miquelon',
    nationality: 'French (Saint Pierre And Miquelon)',
    states: {}
  },
  vc: {
    key3: 'VCT',
    name: 'Saint Vincent and the Grenadines',
    name3: 'Saint Vincent and the Grenadines',
    nameFr: 'Saint-Vincent-et-les Grenadines',
    phoneCode: '1',
    areaCode: ['784'],
    phoneOrder: 20,
    localName: '',
    nationality: 'Saint Vincentian',
    states: {}
  },
  ws: {
    key3: 'WSM',
    name: 'Samoa',
    name3: 'Samoa',
    nameFr: 'Samoa',
    phoneCode: '685',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Samoan',
    states: {}
  },
  sm: {
    key3: 'SMR',
    name: 'San Marino',
    name3: 'San Marino',
    nameFr: 'Saint-Marin',
    phoneCode: '378',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Sammarinese',
    states: {}
  },
  st: {
    key3: 'STP',
    name: 'Sao Tome and Principe',
    name3: 'Sao Tome and Principe',
    nameFr: 'Sao Tomé-et-Principe',
    phoneCode: '239',
    areaCode: [],
    phoneOrder: 0,
    localName: 'São Tomé e Príncipe',
    nationality: 'Sao Tomean',
    states: {}
  },
  sa: {
    key3: 'SAU',
    name: 'Saudi Arabia',
    name3: 'Saudi Arabia',
    nameFr: 'Arabie saoudite',
    phoneCode: '966',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫المملكة العربية السعودية‬‎',
    nationality: 'Saudi Arabian',
    states: {}
  },
  sn: {
    key3: 'SEN',
    name: 'Senegal',
    name3: 'Senegal',
    nameFr: 'Sénégal',
    phoneCode: '221',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Sénégal',
    nationality: 'Senegalese',
    states: {}
  },
  rs: {
    key3: 'SRB',
    name: 'Serbia',
    name3: 'Serbia',
    nameFr: 'Serbie',
    phoneCode: '381',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Србија',
    nationality: 'Serbian',
    states: {}
  },
  sc: {
    key3: 'SYC',
    name: 'Seychelles',
    name3: 'Seychelles',
    nameFr: 'Seychelles',
    phoneCode: '248',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Seychellois',
    states: {}
  },
  sl: {
    key3: 'SLE',
    name: 'Sierra Leone',
    name3: 'Sierra Leone',
    nameFr: 'Sierra Leone',
    phoneCode: '232',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Sierra Leonean',
    states: {}
  },
  sg: {
    key3: 'SGP',
    name: 'Singapore',
    name3: 'Singapore',
    nameFr: 'Singapour',
    phoneCode: '65',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Singaporean',
    states: {}
  },
  sx: {
    key3: 'SXM',
    name: 'Sint Maarten (Dutch part)',
    name3: 'Sint Maarten (Dutch part)',
    nameFr: 'Saint-Martin (partie néerlandaise)',
    phoneCode: '1',
    areaCode: ['721'],
    phoneOrder: 21,
    localName: '',
    nationality: 'Dutch (Sint Maarten)',
    states: {}
  },
  sk: {
    key3: 'SVK',
    name: 'Slovakia',
    name3: 'Slovakia',
    nameFr: 'Slovaquie',
    phoneCode: '421',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Slovensko',
    nationality: 'Slovak',
    states: {}
  },
  si: {
    key3: 'SVN',
    name: 'Slovenia',
    name3: 'Slovenia',
    nameFr: 'Slovénie',
    phoneCode: '386',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Slovenija',
    nationality: 'Slovene',
    states: {}
  },
  sb: {
    key3: 'SLB',
    name: 'Solomon Islands',
    name3: 'Solomon Islands',
    nameFr: 'Salomon (les Îles)',
    phoneCode: '677',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Solomon Islander',
    states: {}
  },
  so: {
    key3: 'SOM',
    name: 'Somalia',
    name3: 'Somalia',
    nameFr: 'Somalie',
    phoneCode: '252',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Soomaaliya',
    nationality: 'Somali',
    states: {}
  },
  za: {
    key3: 'ZAF',
    name: 'South Africa',
    name3: 'South Africa',
    nameFr: 'Afrique du Sud',
    phoneCode: '27',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'South African',
    states: {}
  },
  gs: {
    name: 'South Georgia and the South Sandwich Islands',
    name3: 'South Georgia and the South Sandwich Islands',
    nameFr: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
    key3: 'SGS',
    phoneCode: '',
    localName: '',
    nationality: 'South Georgian, South Sandwich Islander'
  },
  kr: {
    key3: 'KOR',
    name: 'Korea, Republic of',
    name3: 'Korea, Republic of',
    nameFr: 'Corée (la République de)',
    phoneCode: '82',
    areaCode: [],
    phoneOrder: 0,
    localName: '대한민국',
    nationality: 'South Korean',
    states: {}
  },
  ss: {
    key3: 'SSD',
    name: 'South Sudan',
    name3: 'South Sudan',
    nameFr: 'Soudan du Sud',
    phoneCode: '211',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫جنوب السودان‬‎',
    nationality: 'South Sudanese',
    states: {}
  },
  es: {
    key3: 'ESP',
    name: 'Spain',
    name3: 'Spain',
    nameFr: 'Espagne',
    phoneCode: '34',
    areaCode: [],
    phoneOrder: 0,
    localName: 'España',
    nationality: 'Spanish',
    states: {}
  },
  lk: {
    key3: 'LKA',
    name: 'Sri Lanka',
    name3: 'Sri Lanka',
    nameFr: 'Sri Lanka',
    phoneCode: '94',
    areaCode: [],
    phoneOrder: 0,
    localName: 'ශ්‍රී ලංකාව',
    nationality: 'Sri Lankan',
    states: {}
  },
  sd: {
    key3: 'SDN',
    name: 'Sudan',
    name3: 'Sudan',
    nameFr: 'Soudan',
    phoneCode: '249',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫السودان‬‎',
    nationality: 'Sudanese',
    states: {}
  },
  sr: {
    key3: 'SUR',
    name: 'Suriname',
    name3: 'Suriname',
    nameFr: 'Suriname',
    phoneCode: '597',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Surinamese',
    states: {}
  },
  sj: {
    key3: 'SJM',
    name: 'Svalbard and Jan Mayen',
    name3: 'Svalbard and Jan Mayen',
    nameFr: "Svalbard et l'Île Jan Mayen",
    phoneCode: '47',
    areaCode: ['79'],
    phoneOrder: 1,
    localName: '',
    nationality: 'Norwegian (Svalbard And Jan Mayen)',
    states: {}
  },
  sz: {
    key3: 'SWZ',
    name: 'Swaziland',
    name3: 'Eswatini',
    nameFr: 'Swaziland',
    phoneCode: '268',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Swazi',
    states: {}
  },
  se: {
    key3: 'SWE',
    name: 'Sweden',
    name3: 'Sweden',
    nameFr: 'Suède',
    phoneCode: '46',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Sverige',
    nationality: 'Swedish (Sweden)',
    states: {}
  },
  ch: {
    key3: 'CHE',
    name: 'Switzerland',
    name3: 'Switzerland',
    nameFr: 'Suisse',
    phoneCode: '41',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Schweiz',
    nationality: 'Swiss',
    states: {}
  },
  sy: {
    key3: 'SYR',
    name: 'Syrian Arab Republic',
    name3: 'Syrian Arab Republic',
    nameFr: 'République arabe syrienne',
    phoneCode: '963',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫سوريا‬‎',
    nationality: 'Syrian',
    states: {}
  },
  tw: {
    key3: 'TWN',
    name: 'Chinese Taipei',
    name3: 'Taiwan, Province of China',
    nameFr: 'Taïwan (Province de Chine)',
    phoneCode: '886',
    areaCode: [],
    phoneOrder: 0,
    localName: '台灣',
    nationality: 'Taiwanese',
    states: {}
  },
  hk: {
    key3: 'HK',
    name: 'Hong Kong, China',
    name3: 'Hong Kong, Province of China',
    nameFr: 'Hong Kong (Province de Chine)',
    phoneCode: '852',
    areaCode: [],
    phoneOrder: 0,
    localName: '台灣',
    nationality: 'Chinese',
    states: {}
  },
  tj: {
    key3: 'TJK',
    name: 'Tajikistan',
    name3: 'Tajikistan',
    nameFr: 'Tadjikistan',
    phoneCode: '992',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Tadzhik',
    states: {}
  },
  tz: {
    key3: 'TZA',
    name: 'Tanzania, United Republic of',
    name3: 'Tanzania, United Republic of',
    nameFr: 'Tanzanie (la République-Unie de)',
    phoneCode: '255',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Tanzanian',
    states: {}
  },
  th: {
    key3: 'THA',
    name: 'Thailand',
    name3: 'Thailand',
    nameFr: 'Thaïlande',
    phoneCode: '66',
    areaCode: [],
    phoneOrder: 0,
    localName: 'ไทย',
    nationality: 'Thai',
    states: {}
  },
  tl: {
    key3: 'TLS',
    name: 'Timor-Leste',
    name3: 'Timor-Leste',
    nameFr: 'Timor-Leste',
    phoneCode: '670',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'East Timorese',
    states: {}
  },
  tg: {
    key3: 'TGO',
    name: 'Togo',
    name3: 'Togo',
    nameFr: 'Togo',
    phoneCode: '228',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Togolese',
    states: {}
  },
  tk: {
    key3: 'TKL',
    name: 'Tokelau',
    name3: 'Tokelau',
    nameFr: 'Tokelau',
    phoneCode: '690',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Tokelauan',
    states: {}
  },
  to: {
    key3: 'TON',
    name: 'Tonga',
    name3: 'Tonga',
    nameFr: 'Tonga',
    phoneCode: '676',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Tongan',
    states: {}
  },
  tt: {
    key3: 'TTO',
    name: 'Trinidad and Tobago',
    name3: 'Trinidad and Tobago',
    nameFr: 'Trinité-et-Tobago',
    phoneCode: '1',
    areaCode: ['868'],
    phoneOrder: 22,
    localName: '',
    nationality: 'Trinidadian',
    states: {}
  },
  tn: {
    key3: 'TUN',
    name: 'Tunisia',
    name3: 'Tunisia',
    nameFr: 'Tunisie',
    phoneCode: '216',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫تونس‬‎',
    nationality: 'Tunisian',
    states: {}
  },
  tr: {
    key3: 'TUR',
    name: 'Turkey',
    name3: 'Turkey',
    nameFr: 'Turquie',
    phoneCode: '90',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Türkiye',
    nationality: 'Turkish',
    states: {}
  },
  tm: {
    key3: 'TKM',
    name: 'Turkmenistan',
    name3: 'Turkmenistan',
    nameFr: 'Turkménistan',
    phoneCode: '993',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Turkmen',
    states: {}
  },
  tc: {
    key3: 'TCA',
    name: 'Turks and Caicos Islands',
    name3: 'Turks and Caicos Islands',
    nameFr: 'Turks-et-Caïcos (les Îles)',
    phoneCode: '1',
    areaCode: ['649'],
    phoneOrder: 23,
    localName: '',
    nationality: 'Turks and Caicos Islander',
    states: {}
  },
  tv: {
    key3: 'TUV',
    name: 'Tuvalu',
    name3: 'Tuvalu',
    nameFr: 'Tuvalu',
    phoneCode: '688',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Tuvaluan',
    states: {}
  },
  ug: {
    key3: 'UGA',
    name: 'Uganda',
    name3: 'Uganda',
    nameFr: 'Ouganda',
    phoneCode: '256',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Ugandan',
    states: {}
  },
  ua: {
    key3: 'UKR',
    name: 'Ukraine',
    name3: 'Ukraine',
    nameFr: 'Ukraine',
    phoneCode: '380',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Україна',
    nationality: 'Ukrainian',
    states: {}
  },
  ae: {
    key3: 'ARE',
    name: 'United Arab Emirates',
    name3: 'United Arab Emirates',
    nameFr: 'Émirats arabes unis',
    phoneCode: '971',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫الإمارات العربية المتحدة‬‎',
    nationality: 'Emirati',
    states: {}
  },
  gb: {
    key3: 'GBR',
    name: 'United Kingdom',
    name3: 'United Kingdom of Great Britain and Northern Ireland',
    nameFr: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord",
    phoneCode: '44',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'British',
    states: {}
  },
  us: {
    key3: 'USA',
    name: 'United States',
    name3: 'United States of America',
    nameFr: "États-Unis d'Amérique",
    phoneCode: '1',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'American (United States)',
    states: {
      AK: 'Alaska',
      AL: 'Alabama',
      AR: 'Arkansas',
      AZ: 'Arizona',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DC: 'District of Columbia',
      DE: 'Delaware',
      FL: 'Florida',
      GA: 'Georgia',
      HI: 'Hawaii',
      IA: 'Iowa',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      MA: 'Massachusetts',
      MD: 'Maryland',
      ME: 'Maine',
      MI: 'Michigan',
      MN: 'Minnesota',
      MO: 'Missouri',
      MS: 'Mississippi',
      MT: 'Montana',
      NC: 'North Carolina',
      ND: 'North Dakota',
      NE: 'Nebraska',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NV: 'Nevada',
      NY: 'New York',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PA: 'Pennsylvania',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VA: 'Virginia',
      VT: 'Vermont',
      WA: 'Washington',
      WI: 'Wisconsin',
      WV: 'West Virginia',
      WY: 'Wyoming'
    }
  },
  uy: {
    key3: 'URY',
    name: 'Uruguay',
    name3: 'Uruguay',
    nameFr: 'Uruguay',
    phoneCode: '598',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Uruguayan',
    states: {}
  },
  uz: {
    key3: 'UZB',
    name: 'Uzbekistan',
    name3: 'Uzbekistan',
    nameFr: 'Ouzbékistan',
    phoneCode: '998',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Oʻzbekiston',
    nationality: 'Uzbekistani',
    states: {}
  },
  vu: {
    key3: 'VUT',
    name: 'Vanuatu',
    name3: 'Vanuatu',
    nameFr: 'Vanuatu',
    phoneCode: '678',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Ni-Vanuatu',
    states: {}
  },
  va: {
    key3: 'VAT',
    name: 'Holy See (Vatican City State)',
    name3: 'Holy See',
    nameFr: 'Saint-Siège',
    phoneCode: '39',
    areaCode: ['06698'],
    phoneOrder: 1,
    localName: 'Città del Vaticano',
    nationality: 'Italian (Vatican City)'
  },
  ve: {
    key3: 'VEN',
    name: 'Venezuela, Bolivarian Republic of',
    name3: 'Venezuela (Bolivarian Republic of)',
    nameFr: 'Venezuela (République bolivarienne du)',
    phoneCode: '58',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Venezuelan',
    states: {}
  },
  vn: {
    key3: 'VNM',
    name: 'Viet Nam',
    name3: 'Viet Nam',
    nameFr: 'Viet Nam',
    phoneCode: '84',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Việt Nam',
    nationality: 'Vietnamese',
    states: {}
  },
  wf: {
    key3: 'WLF',
    name: 'Wallis and Futuna',
    name3: 'Wallis and Futuna',
    nameFr: 'Wallis-et-Futuna',
    phoneCode: '681',
    areaCode: [],
    phoneOrder: 0,
    localName: 'Wallis-et-Futuna',
    nationality: 'Wallisian, Futunan',
    states: {}
  },
  eh: {
    key3: 'ESH',
    name: 'Western Sahara',
    name3: 'Western Sahara ',
    nameFr: 'Sahara occidental',
    phoneCode: '212',
    areaCode: ['5288', '5289'],
    phoneOrder: 1,
    localName: '‫الصحراء الغربية‬‎',
    nationality: 'Sahrawi',
    states: {}
  },
  ye: {
    key3: 'YEM',
    name: 'Yemen',
    name3: 'Yemen',
    nameFr: 'Yémen',
    phoneCode: '967',
    areaCode: [],
    phoneOrder: 0,
    localName: '‫اليمن‬‎',
    nationality: 'Yemeni',
    states: {}
  },
  zm: {
    key3: 'ZMB',
    name: 'Zambia',
    name3: 'Zambia',
    nameFr: 'Zambie',
    phoneCode: '260',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Zambian',
    states: {}
  },
  zw: {
    key3: 'ZWE',
    name: 'Zimbabwe',
    name3: 'Zimbabwe',
    nameFr: 'Zimbabwe',
    phoneCode: '263',
    areaCode: [],
    phoneOrder: 0,
    localName: '',
    nationality: 'Zimbabwean',
    states: {}
  }
};
